.input-prefix {
  position: absolute;
  top: 0;
  left: -12px;
  background-color: gray;
  padding: 8px;
}

.btn {
  background: #fadf18;
}
