.main {
  background: url("https://2gethrteam.s3.ap-south-1.amazonaws.com/2gethr-store-assets/bg.jpg");
  background-position: 10% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}

.header {
  height: 14%;
}

.content {
  /* margin-top: 6%; */
  /* padding: 2%; */
  height: 70vh;
}
