.alert {
  position: absolute;
  bottom: 4%;
  /* left: 2%; */
  /* width: 0%; */
  left: 3%;
  transition: ease-in;
  animation-name: alert-animation;
  animation-duration: 0.5s;
  overflow: hidden;
}

@keyframes alert-animation {
  0% {
    left: -10%;
  }

  /* 50% {
    left: 0%;
  } */

  100% {
    left: 3%;
  }
}
