.overlay {
  transition: 0.5s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  height: 100vh;
  width: 100vh;
  background-color: white;
  z-index: 100;
  opacity: 0.3;
}

.loader {
  /* position: absolute;
  top: 50%;
  left: 50%;
  text-align: center; */
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  /* top: 50%;
  left: 50%; */
  width: 40px;
  height: 40px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border-top: 2px solid black;
  border-right: 2px solid transparent;
  animation: spinner 0.6s linear infinite;
}
